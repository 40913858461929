<template lang="pug">
.flex.flex-col.gap-2
  label.btn-confirm.text-sm.font-medium(:for='name' v-if='label' :class='{ "required-input" : required }') {{ label }}
  Input#confirm.text-sm.transition-all.custom-shadow(
    v-model='input'
    :name='name'
    :placeholder='placeholder'
    class='focus-visible:ring-0 focus-visible:ring-offset-0'
  )
</template>

<script setup lang="ts">
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'

interface Props {
  modelValue: string
  name: string
  label: string
  placeholder: string
  required?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  required: false,
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number): void
}>()

const { color } = useBrandColor()

const input = computed({
  get() {
    return props.modelValue
  },
  // setter
  set(newValue) {
    emit('update:modelValue', newValue)
  },
})
</script>

<style scoped lang="stylus">
.custom-shadow:focus-visible
  box-shadow inset 0 0 0 1px v-bind(color)
.required-input:after {
  content:" *";
  color: red;
}
</style>
